import React from "react"
import Seo from "../components/seo/Seo"

import Section from "../components/section/Section"
import SectionInfo from "../components/section/SectionInfo"
import SectionGrid from "../components/section/SectionGrid"
import SectionHeading from "../components/section/SectionHeading"
import SectionImage from "../components/section/SectionImage"
import SectionParagraph from "../components/section/SectionParagraph"

import ueber_uns from "../assets/images/presentational/ueber-uns.webp"
import expertise from "../assets/images/presentational/expertise.webp"
import entwicklung from "../assets/images/presentational/entwicklung.webp"
import technisches_asset_und_property_management from "../assets/images/presentational/technisches-asset-und-property-management.webp"
import SectionBanner from "../components/section/SectionBanner"

const About = () => {
  return (
    <>
      <Seo
        title={"Über uns | Avalum"}
        description={
          "Avalum unterstützt Immobilieneigentümer und Immobilienunternehmen, ob Bestandshalter oder Projektentwickler, bei der Bewältigung komplexer Situationen."
        }
      />
      <Section>
        <SectionGrid>
          <SectionImage src={ueber_uns} />
          <SectionInfo>
            <SectionHeading>Über uns</SectionHeading>
            <SectionParagraph>
              Avalum unterstützt Immobilieneigentümer und Immobilienunternehmer,
              ob als Instandhalter oder als Projektentwickler, bei der
              Bewältigung komplexer Situationen.
            </SectionParagraph>
            <SectionParagraph>
              Wir stellen uns jeder Herausforderung und suchen immer optimale
              Lösungen.
            </SectionParagraph>
          </SectionInfo>
        </SectionGrid>
      </Section>
      <Section isEven={true}>
        <SectionGrid sx={"flex-row-reverse"}>
          <SectionImage src={expertise} />
          <SectionInfo>
            <SectionHeading>Expertise</SectionHeading>
            <SectionParagraph>
              Avalum ist im operativen so auch im strategischen
              Immobiliengeschäft tätig und verfügt über Expertise im Bau-,
              Immobilien- und Projektmanagement.
            </SectionParagraph>
            <SectionParagraph>
              Unsere Ziele sind gut überlegt und umsetzbar, das Erreichte ist
              messbar.
            </SectionParagraph>
          </SectionInfo>
        </SectionGrid>
      </Section>
      <Section>
        <SectionGrid>
          <SectionImage src={entwicklung} />
          <SectionInfo>
            <SectionHeading>Entwicklung</SectionHeading>
            <SectionParagraph>
              Da das Leben heute mit einer immer höherer Dynamik voranschreitet
              und sich in stäter Veränderung befindet, ist es sehr wichtig,
              nicht nur Ziele zu setzen, sondern auch, bei ihrem Realisieren
              optimal überlegt vorzugehen.
              <br />
              <br />
              Dabei greifen wir nicht nur nach den schon vorhandenen
              Erfahrungen, sondern sind stets entschlossen, völlig neue
              Umsetzungs- und Leistungsideen zu entwickeln.
              <br />
              <br />
              Das Unmögliche können auch wir nicht möglich machen, aber stets
              streben wir an, unter den gegebenen Umständen das optimal Mögliche
              zu erreichen.
            </SectionParagraph>
          </SectionInfo>
        </SectionGrid>
      </Section>
      <SectionBanner
        bg={technisches_asset_und_property_management}
        to={"/geschaeftsfelder"}
        title={"Geschäftsfelder ansehen"}
        heading={"Sehen Sie, in welchen Geschäftsfeldern wir Profis sind"}
      />
    </>
  )
}

export default About
